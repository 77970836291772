<template>
  <el-card  class="apply_submit" style="height: 99%;position: relative;">
      <el-steps :active="ActiveInd" simple style="background:#fff8ef;">
        <el-step title="授信审批" icon="el-icon-upload">
          <template slot="icon">
            <i class="iconfont icon-yinzhang"></i>
          </template>
        </el-step>
        <el-step title="店铺授权" icon="el-icon-upload">
          <template slot="icon">
            <i class="iconfont icon-dianpu"></i>
          </template>
        </el-step>
        <el-step title='申请融资' icon="el-icon-picture">
          <template slot="icon">
            <i class="iconfont icon-qian"></i>
          </template>
        </el-step>
        <el-step title="融资成功" icon="el-icon-picture">
          <template slot="icon">
            <i class="iconfont icon-qian4"></i>
          </template>
        </el-step>
      </el-steps>
      <template v-if="ActiveInd===0">
          <div style="display: flex;padding: 5rem 11rem 0 11rem;height:31.25rem;overflow: auto" :key="TableKey">
            <div style="width: 50%;font-size: 1.125rem">
              <el-form label-position="right" label-width="10rem" class="Apply_form">
                <el-form-item label="公司名称">
                  <el-input placeholder="请输入公司名称" v-model="CreditFormData.company_name"></el-input>
                </el-form-item>
                <el-form-item label="营业执照">
                  <UploadDoc :value="CreditFormData.business_image"></UploadDoc>
                </el-form-item>
                <el-form-item label="公司征信报告">
                  <UploadDoc :value="CreditFormData.company_credit_image"></UploadDoc>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <div style="line-height: 1.25rem">
                      公司主对公账户
                      半年银行流水
                    </div>
                  </template>
                  <UploadDoc :value="CreditFormData.bank_water_image"></UploadDoc>
                </el-form-item>
              </el-form>
            </div>
            <div style="width: 50%;margin-left: 6%">
              <el-form label-position="right" label-width="10rem" class="Apply_form">
                <el-form-item label="法人姓名">
                  <el-input placeholder="请输入法人姓名" v-model="CreditFormData.legal_person" ></el-input>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <div style="line-height: 1.25rem">
                      法人身份证<br/>(正反两面)
                    </div>
                  </template>
                  <UploadDoc :value="CreditFormData.id_card_image"></UploadDoc>
                </el-form-item>
                <el-form-item label="法人征信报告">
                  <UploadDoc :value="CreditFormData.credit_image"></UploadDoc>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <div style="line-height: 1.25rem">
                      法人户口本<br/>(户主页及个人页)
                    </div>
                  </template>
                  <UploadDoc :value="CreditFormData.people_book_image"></UploadDoc>
                </el-form-item>
                <el-form-item label="是否已婚 ">
                  <el-radio v-model="CreditFormData.marriage" :label="1">未婚</el-radio>
                  <el-radio v-model="CreditFormData.marriage" :label="2">已婚</el-radio>
                </el-form-item>
                <el-form-item label="法人结婚证 " v-show="CreditFormData.marriage===2">
                  <UploadDoc :value="CreditFormData.marriage_image"></UploadDoc>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="footer">
            <el-button class="main_color next main_btn2" size="small" @click="LoansReq">下一步</el-button>
          </div>
      </template>
      <template v-if="ActiveInd===1">
        <div style="margin: 2rem 0.75rem">
          <el-button class="add_btn main_btn4" size="small" @click="AddShop(1)">+添加跨境店铺</el-button>
          <el-button class="add_btn main_btn4" size="small" @click="AddShop(2)">+添加本土店铺</el-button>
        </div>
         <el-table
             border
             :height="450"
             :data="TableList"
             :header-cell-style="{'text-align':'center','background-color':'#f5f7fa','font-size':'1.125rem'}"
             :cell-style="{'text-align':'center','font-size':'1.125rem'}"
             empty-text="暂无数据,请添加授权店铺"
         >
          <el-table-column label="店铺名称">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
           <el-table-column label="店铺类型">
             <template slot-scope="scope">
               <span>{{['跨境店铺','本土店铺'][scope.row.type-1]}}</span>
             </template>
           </el-table-column>
           <el-table-column label="授权状态">
             <template slot-scope="scope">
               <span>{{['未授权','授权成功','授权失败'][scope.row.auth_status-1]}}</span>
             </template>
           </el-table-column>
           <el-table-column label="授权时间">
             <template slot-scope="scope">
               <span>{{scope.row.auth_time || '-'}}</span>
             </template>
           </el-table-column>
           <el-table-column label="操作">
             <template slot-scope="scope">
              <el-link type="primary" :underline="false" style="margin-right: 1rem;font-size: 1.125rem" @click="AddShop(scope.row.seller_id)">重新授权</el-link>
              <el-link type="danger" style='font-size: 1.125rem' :underline="false" @click="RemoveShop(scope.row.id)">删除</el-link>
             </template>
           </el-table-column>
         </el-table>
        <div class="footer">
          <el-button class="main_color next main_btn2" size="small" @click="ActiveInd=0">上一步</el-button>
          <el-button class="main_color next main_btn2" size="small" @click="Apply" :disabled="!this.TableList.length">申请融资</el-button>
        </div>
      </template>
      <template v-if="ActiveInd===2">
        <div style="display: flex;padding: 16.75rem 13rem 0 11rem;justify-content: center;">
          <span style="font-size: 2rem" v-if="orderStatus.order_status!==3">融资申请提交成功，请耐心等待工作人员审核...</span>
          <div style="font-size: 2rem;display: flex;flex-direction:column;justify-content: center;align-items: center" v-else>
               <span style="margin-bottom: 1rem">驳回原因</span>
            {{orderStatus.refuse_desc}}
          </div>
        </div>
        <div class="footer">
          <el-button class="main_color next main_btn2" size="small" @click="ActiveInd=1" v-if="orderStatus.order_status===3">上一步</el-button>
          <el-button class="main_color next main_btn2" size="small" @click="$router.push('/dashboard')">返回首页</el-button>
        </div>
      </template>
      <template v-if="ActiveInd===3">
        <div style="display: flex;justify-content: center;align-items: center;margin-top: 17.625rem">
             <el-image style="width: 3.875rem" :src="require('@/assets/order_success.png')"></el-image>
             <span style="font-size: 2.375rem;margin-left: 1rem">融资成功</span>
        </div>
        <div class="footer">
          <el-button class="main_color next main_btn2" size="small" @click="$router.push('/dashboard')">返回首页</el-button>
        </div>
      </template>
    <el-dialog
      :visible.sync="ShopVisible"
      width="62.5rem"
      :close-on-click-modal="false"
      class="details-dialog-css"
  >
    <div style="display: flex;">
        <div style="flex:1;display: flex;flex-direction: column;justify-content: center;align-items: center;border-right: 1px solid #EFEFEF">
          <span style="font-size: 2rem">直接授权</span>
          <el-image style="width: 19.375rem;margin: 2rem" :src="require('@/assets/Direct_authorization.png')"></el-image>
          <span style="margin-bottom:2rem;font-size: 1rem">点击立即授权后，登入美客多账号授权</span>
          <el-button style="font-size: 1rem" class="shop_btn add_btn main_btn4" @click="OpenInThis">立即授权</el-button>
        </div>
        <div style="flex:1;display: flex;flex-direction: column;justify-content: center;align-items: center">
          <span style="font-size: 2rem">去其他浏览器授权</span>
          <el-image style="width: 16.875rem;margin:2.5rem 0 2.375rem" :src="require('@/assets/Skip_authorization.png')"></el-image>
          <span style="margin-bottom:2rem;font-size: 1rem">复制授权链接，前往已登陆美客多账号的浏览器进行授权</span>
          <el-button class="shop_btn btn_color_1" @click="CopyLink">复制授权链接</el-button>
        </div>
    </div>
  </el-dialog>

  </el-card>
</template>
<script>
import {ERP_URL} from "../../../../config/index"
import UploadDoc from "@/components/picture/UploadDoc.vue";

export default {
  name: "Accredit",
  components: {UploadDoc},
  data(){
    return {
      //当前商家id
      shop_URL:'',
      ActiveInd:0,
      //添加店铺
      ShopVisible:false,
      TableList:[],
      CreditFormData:{
        company_name:'',//公司名称
        legal_person:'',//法人名称
        company_credit_image:	[],//(公司征信报告)
        business_image:	[],//(公司营业执照)
        bank_water_image:	[],//(公司主对公账户半年流水)
        id_card_image:	[],//(法人身份证)
        credit_image:	[],//(法人征信报告)
        people_book_image:[],//(法人户口本)
        marriage_image:	[],//(法人结婚证)
        marriage:1,//1未婚2已婚
      },
      //当前订单id
      orderID:0,
      //当前订单
      orderStatus:{
        order_status:0,
        refuse_desc:'',
      },
      //解决样式不更新
      TableKey:false,
    }
  },
  mounted() {
  },
  activated() {
    this.ActiveInd=this.$utils.CacheSessionGet('ActiveInd') || 0
    this.GetShopList()
    this.orderID=this.$utils.CacheSessionGet('order_id')
    if(this.orderID){
      this.GetCreditFormData(this.orderID)
    }
  },
  methods:{
    //获取上传信息
    GetCreditFormData(id){
        this.$api.post('v1/api/makafin/loan_order/get',{order_id:id},res=>{
        let obj={
            company_name:res.company_name,//公司名称
            legal_person:res.legal_person,//法人名称
            company_credit_image:res.company_credit_image,//(公司征信报告)
            business_image:	res.business_image,//(公司营业执照)
            bank_water_image:	res.bank_water_image,//(公司主对公账户半年流水)
            id_card_image:res.id_card_image,//(法人身份证)
              credit_image:	res.credit_image,//(法人征信报告)
              people_book_image:res.people_book_image,//(法人户口本)
              marriage_image:	res.marriage_image,//(法人结婚证)
            marriage:res.marriage,//1未婚2已婚
          }
          this.CreditFormData=obj
          this.orderStatus= {
            order_status:res.order_status,
            refuse_desc:res.refuse_desc
          }
          this.TableKey=!this.TableKey
        })
    },
    //获取店铺列表
    GetShopList(){
      this.$api.post('v1/api/erp/base_shop/tabulation',{},res=>{
        this.TableList=res.list
        if(this.ShopVisible){
          this.ShopVisible=false
        }
      })
    },
    //资料确认
    LoansReq(){
      if(this.CreditFormData.company_name.trim()===''){
        this.$message.error('请完善公司名称')
        return
      }
      if(this.CreditFormData.legal_person.trim()===''){
        this.$message.error('请完善法人名称')
        return
      }
      let Arr=[]
      for (let key in this.CreditFormData) {
          if(key !=='marriage_image' && Array.isArray(this.CreditFormData[key])){
            Arr.push(this.CreditFormData[key])
          }
      }
      if(!Arr.every(item=>Array.isArray(item) && item.length>0)){
        this.$message.error('请完善资料')
        return
      }
      if(this.CreditFormData.marriage===2){
        if(!this.CreditFormData.marriage_image.length){
          this.$message.error('请完善资料')
          return
        }
      }
      this.ActiveInd=1
    },
    //设置路径
    SetURL(id){
      let number= this.$utils.cookieGet('__ERP_IDENTITY_NUMBER__');
      let token=  this.$utils.cookieGet('__ERP_IDENTITY_TOKEN__');
      let token_key= this.$utils.cookieGet('__ERP_IDENTITY_TOKEN__KEY__');
      let seller_id= this.$utils.cookieGet('__ERP_SELLER_ID__');
      let is_moxige;
      is_moxige = id == 2;
      this.shop_URL= ERP_URL +
          'v1/api/erp/base_shop/auth?' +
          'reqno='+this.$utils.reqno() +
          '&shop_id=seller_' +seller_id+'_'+id+
          '&erp_identity_token='+token +
          '&erp_identity_token_key='+token_key +
          '&erp_identity_number='+number+
          '&is_moxige='+is_moxige
    },
    //添加店铺 & 重新授权
    AddShop(id){
      this.SetURL(id)
      this.ShopVisible=true
    },
    //立即授权
    OpenInThis(){
      window.open(this.shop_URL);
      this.$confirm('是否已经授权成功',{
        confirmButtonText: '是',
        cancelButtonText:'否',
        closeOnClickModal: false,
        customClass:'accredit_confirm',
      }).then(()=>{
        this.GetShopList()
      }).catch(()=>{})
    },
    //复制链接
    CopyLink(){
        this.$utils.copyString(this.shop_URL,this.$message)
        this.$confirm('是否已经授权成功',{
          confirmButtonText: '是',
          cancelButtonText:'否',
          closeOnClickModal: false,
          customClass:'accredit_confirm',
        }).then(()=>{
          this.GetShopList()
        }).catch(()=>{})
    },
    //删除店铺
    RemoveShop(id){
      this.$confirm('确定删除该店铺?',{
        type:'warning'
      }).then(()=>{
        this.$api.post('v1/api/erp/base_shop/del',{id:id},res=>{
          this.$message({
            message:'删除成功',
            type:'success',
            customClass:'messageIndex'
          })
          this.GetShopList()
        })
      }).catch(()=>{})

    },
    //申请融资
    Apply(){
      this.orderStatus={
        order_status:1,
      }
      let url=''
      let obj={}
      let id=this.$utils.CacheSessionGet('Goods_id') || 0
      // if(this.orderID){
      //   url='v1/api/makafin/loan_order/edit'
      //   obj={order_id:this.orderID,product_id:id,...this.CreditFormData}
      // }else{
        url='v1/api/makafin/loan_order/add'
        obj={product_id:id,...this.CreditFormData}
      // }
      this.$api.post(url, obj,res=>{
        this.ActiveInd=2
      })
    },
  }

}
</script>
<style>
.Apply_form .el-form-item__label{
  font-size: 1.125rem;
}
.accredit_confirm .el-message-box__content, .accredit_confirm .el-message-box__btns{
  font-size: 1.625rem;
  text-align: center;
}
.accredit_confirm .el-button:nth-child(1){
  background: #FFF;
  width: 7.125rem;
  color: #000;
  font-size: 1.125rem;
  border:1px solid #FFBA56!important;
}
.accredit_confirm .el-button:nth-child(1):hover, .accredit_confirm .el-button:nth-child(1):focus{
  color:#FFBA56;
}
.accredit_confirm .el-button:nth-child(1):active{
  color:#FFBA56;
}
.accredit_confirm .el-button:nth-child(2){
  margin-left: 5.125rem;
  margin-top: 2.625rem;
  width: 7.125rem;
  color: #000;
  font-size: 1.125rem;
  background: #FFBA56;
}
.accredit_confirm .el-button:nth-child(2):hover,.accredit_confirm .el-button:nth-child(2):focus{
  background: #F8B14A;
  color:#000;
}
.accredit_confirm .el-button:nth-child(2):active{
  background: #F8B14A;
  color:#000;
}
</style>
<style scoped>
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #333 !important;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #FFBA56 !important;
  border-color: #FFBA56 !important;
}
.shop_btn{
  line-height: 0.97rem;
  font-weight: 700;
}
.btn_color_1{
  color: #fff;
  background:#4F8EC8 ;
}
.btn_color_1.el-button:hover,.btn_color_1.el-button:focus{
  background: #699FD0;
}
.btn_color_1.el-button:active{
  background: #4685BF;
}
.footer{
  width: 100%;
  text-align: center;
  position: absolute;
  right: 0;
  bottom:5rem;
}
.next{
  font-size: 1.125rem;
  color: #333333;
  width: 9.38rem;
  font-weight: bold;
}
.add_btn{
  width: 10rem;
  font-size: 1.125rem;
  color:#333;
  background: #FFBA56;
}
.main_color{
  background: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);
}
.main_color.is-disabled:hover{
  background: #EFEFEF;
  color:#999;
}
.main_color.is-disabled{
  background: #EFEFEF;
  color:#999;
}
</style>
<style>

.apply_submit .is-finish{
  color: #FFBA56!important;
}
.apply_submit .is-finish+.el-step__arrow:before , .apply_submit .is-finish+.el-step__arrow:after{
  background: #FFBA56!important;
}
</style>